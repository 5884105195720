import React from "react";

// components
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import FaqAccordion from "../../../components/faq/faq-accordion";

const OnlineBankingDisputeFAQs = () => {
  const title = "Online Banking Dispute FAQs",
    description = "WaFd Bank Online banking dispute faqs";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/online-banking/online-banking-dispute-faqs"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/online-banking",
      title: "Mobile & Online Banking"
    },
    {
      id: 3,
      active: true,
      title: "Online Banking Dispute FAQs"
    }
  ];

  const faqAccordionData = {
    id: "olb-dispute-faq-accordion",
    title: "Online Banking Dispute FAQs",
    faqCategoryNames: ["Online Banking Dispute"]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />

      <FaqAccordion {...faqAccordionData} />
    </SecondaryLanding>
  );
};

export default OnlineBankingDisputeFAQs;
